import React from 'react';
import './Portfolio.css';
import {restaurant, inno, ecommerce, blogsite} from './index';

function Portfolio() {
  const projects = [
    {
      title: 'Innovaira',
      description: 'The official website of our parent company, Innovaira.',
      imageUrl: inno,
      link: 'https://innovaira.devtodefy.com/'
    },
    {
      title: 'Giovani',
      description: 'A stunning and user-friendly website for a modern luxury restaurant.',
      imageUrl: restaurant,
      link: 'https://giovani.devtodefy.com/'
    },
    {
      title: 'Nuvia',
      description: 'An engaging and seamless shopping experience for an online digital download store.',
      imageUrl: ecommerce,
      link: 'https://nuvia.devtodefy.com/' // Replace with actual URL
    },
    {
      title: '',
      description: 'A clean and modern blog platform for content creators and readers.',
      imageUrl: blogsite,
      link: '' // Replace with actual URL
    }
    // Add more projects as needed
  ];

  return (
    <div className="portfolio-page">
      <header className="header">
        <h1 className="gradient-text">Our Portfolio</h1>
        <p className="subtitle2">We push the boundaries of web design and development to create stunning, high-performing websites.Explore our portfolio to see how we can transform your digital presence.</p>
      </header>

      <section id="projects" className="project-showcase">
        <div className="projects">
          {projects.map((project, index) => (
            <div className="project" key={index}>
              <img src={project.imageUrl} alt={project.title} />
              <div className="project-info">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
                <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-button">View Project</a>
              </div>
            </div>
          ))}
        </div>
      </section>
      <p className="disclaimer"><br/><br/>*These projects shown are examples of our work and may not represent current client websites, except for Innovaira. We showcase these projects to highlight our skills and capabilities in web design & development, as well as UX/UI.</p>
    </div>
  );
}

export default Portfolio;
